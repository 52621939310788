import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import "./style.scss"
import NewButton from "../NewButton"
import { useWindowWidth } from "../../services/hooks"
import {accessoryInfo} from "../../services/accessoryDictionary"

const AccessoryItem = ({ product, color, type, position="N/A" }) => {
  const { title, handle, productImage1, price } = product
  const [isHovered, setIsHovered] = useState(false)
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()

  useEffect(() => {
      setIsMobile(windowWidth < 578)
  }, [windowWidth])

  const accessoryInfoMeta = accessoryInfo.find(obj => {
    return obj.title.toLowerCase() === title.toLowerCase()
  })

  return (
    <Link
      className="accessory-item"
      to={type === "contacts" ? "/contact-lenses" :`/accessory/${handle}`}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
      data-color={color}
    >
      <div
        className="image-container"
        style={{
          backgroundImage: `url(${productImage1})`,
          backgroundSize: "contain",
        }}
      >
        {isHovered && !isMobile && (
          <div className="button-container">
            <NewButton label="Shop Now" to={type === "contacts" ? "/contact-lenses" :`/accessory/${handle}`} />
          </div>
        )}
      </div>
      <div className="product-info-container">
        <div className="info">
          <p className="title text h5">{accessoryInfoMeta?.altTitle || title}</p>
          <p className="price text neue">${Number(price).toFixed(2)}</p>
        </div>
      </div>
      {isMobile &&
        <div className="cta-button">
          <NewButton label="Shop Now" to={type === "contacts" ? "/contact-lenses" :`/accessory/${handle}`} color='black'/>
        </div>
      }
    </Link>
  )
}

export default AccessoryItem
