import { useState, useEffect } from "react"

export const useAbTest = () => {
  const [abTestActive, setAbTestActive] = useState(false)

  const checkABTests = (retries = 0) => {
    if (typeof window !== "undefined" && window?.abtesting) {
      if (window?.abtesting?.tests?.hubble) {
        console.log('AB Test Active')
        setAbTestActive(true)
      }
    } else if (retries < 50) {
      setTimeout(() => checkABTests(retries+1), 100)
    }
  }

  useEffect(() => {checkABTests()}, [])

  return abTestActive
}

export default useAbTest
