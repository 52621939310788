import React, { useEffect,useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useWindowWidth } from "../services/hooks"

import '../styles/pages/search.scss'
import ProductItem from "../components/ProductItem"
import AccessoryItem from "../components/AccessoryItem"
import PcpItem from "../components/PcpItem"
import {contactLenses as getContactLenses, contactLenses} from "../components/LensCompare/mockedData"
import WebpImage from "../components/WebpImage"
import avgProducts from "../services/avg-products.json"
import useIsLifemart from "../components/Lifemart/hooks/useIsLifemart";
import useIsMilitary from "../components/Military/hooks/useIsMilitary";
import {getHubblePricing} from "../services/data";

const colorTags = {
  glasses: "green",
  sunglasses: "orange",
  contacts: "blue",
}

const categories = {
  sunglasses: "sunglasses",
  glasses: "glasses",
  accessory: "accessory",
  contacts: "contacts",
}

const searchKeywords = {
  sunglasses: {
    keyWords: {
      sunglasses: "sunglasses"
    },
  },
  glasses: {
    keyWords: {
      glasses: "glasses",
      eyeglasses: "eyeglasses"
    },
  },
  accessory: {
    keyWords: {
      accessories: "accessories",
      accessory: "accessory",
    },
  },
  contacts: {
  keyWords: {
      contacts: "contacts",
      contactsLens: "contacts lens",
      contactsLense: "contacts lenses",
      contactLense: "contact lenses",
      lens: "lens",
      lense: "lenses"
    },
  }
}

const IndexPage = ({ data })  => {
  let formattedAvgProducts = avgProducts.flatMap(e => e.options.map((option, index) => ({
    ...option,
    price: `$${option.sale_price}`,
    originalPrice: `$${option.price}`,
    link: `/avg-product/${e.value}${index > 0 ? `#${option?.quantity}` : ""}`,
    product: option.value,
    type: "avg",
  })))
  const [baseFrames, setBaseFrames] = useState([])
  const [sunglassesFrames, setSunglassesFrames] = useState([])
  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const hubblePricing = getHubblePricing()
  const [hubbleLensesItems, setHubbleLensesItems] = useState(contactLenses(!isLifemart && !isMilitary, hubblePricing))
  const [avgProductsItems, setAvgProductsItems] = useState(formattedAvgProducts)
  const [filteredFrames, setFilteredFrames] = useState([])
  const [searchText, setSearchText] = useState("")
  const [blogs, setBlogs] = useState([])
  const [isMobile, setIsMobile] = useState('')
  const [searchType, setSearchType] = useState(categories.glasses)
  const base_filter = [...data.hubbleapi.hubbleFrameProducts]
  const hubbleAccessories = data.hubbleapi.hubbleAccessoryProducts
  const articlesList = data.allContentfulBlog.edges.map(({ node }) => node)
  const windowWidth = useWindowWidth()

  useEffect(() => {
    if (windowWidth > 768) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [windowWidth])

  useEffect(() => {
    const sunglassesFrames = base_filter.filter(
      ({ collectionString, sunglassesAvailable }) => {
        const collectionArray = collectionString.split(",")

        return (
          collectionArray.includes("Hubble") &&
          !collectionArray.includes("Modern") &&
          sunglassesAvailable
          )
      }
    )
    setSunglassesFrames(sunglassesFrames)

    const glassesFrames = base_filter.filter(
      ({ collectionString }) => {
        const collectionArray = collectionString.split(",")

        return (
          collectionArray.includes("Hubble") &&
          !collectionArray.includes("Modern")
        )
      }
    )
    setBaseFrames(glassesFrames)
  }, [])

  const getCategory = () => {
    return Object.values(categories).filter((category) => {
      if (Object.values(searchKeywords[category].keyWords).some((value) => value.startsWith(searchText) || searchText.includes(value))) {
        return categories[category]
      }
    })[0]
  }

  const filterProducts = (data) => {
    return data.filter(({ title }) => title.toLowerCase().includes(searchText.toLowerCase()))
  }

  // Search logic
  const searchProducts = () => {
    switch (getCategory()) {
      case categories.sunglasses:
        setSearchType(categories.sunglasses)
        const filteredSunglasses = filterProducts(sunglassesFrames)
        setFilteredFrames(filteredSunglasses?.length ? filteredSunglasses : sunglassesFrames)
        break;
      case categories.glasses:
        setSearchType(categories.glasses)
        const filteredGlasses = filterProducts(baseFrames)
        setFilteredFrames(filteredGlasses?.length ? filteredGlasses : baseFrames)
        break;
      case categories.accessory:
        setSearchType(categories.accessory)
        const filteredAccessories = filterProducts(hubbleAccessories)
        setFilteredFrames(filteredAccessories?.length ? filteredAccessories : hubbleAccessories)
        break;
      case categories.contacts:
        setSearchType(categories.contacts)
        const filteredContacts = filterProducts([...hubbleLensesItems, ...(avgProductsItems)])
        setFilteredFrames(filteredContacts?.length ? filteredContacts : [...hubbleLensesItems, ...(avgProductsItems)])
        break;
      default:
        const accessories = filterProducts(hubbleAccessories)
        const glasses = filterProducts(baseFrames)
        const contacts = filterProducts([...hubbleLensesItems, ...(avgProductsItems)])
        setSearchType(undefined)
        setFilteredFrames({accessories, contacts, glasses})
    }
  }

  useEffect(() => {
    searchProducts()
    const filteredBlogs = articlesList.filter(({ title }) => {
      return title.toLowerCase().includes(searchText);
    })
    setBlogs(filteredBlogs)
  }, [searchText])

  const schemaMarkup = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.hubblecontacts.com/search?q={search_term_string}",
          "name": "Search Hubble Contacts",
          "query-input": "required name=search_term_string",
          "@id": "https://www.hubblecontacts.com/#SearchAction"
      },
      "url": "https://www.hubblecontacts.com/",
      "name": "Hubble Contacts",
      "@id": "https://www.hubblecontacts.com/#WebSite"
  }

  ]

  return (
    <Layout>
      <SEO title="Search for information on Hubble Contacts and Ordering Online"
        schemaMarkup={schemaMarkup}
        description="Search Hubble Contacts to find the contact lens products you’re looking for. Discover Hubble’s affordable daily contact lenses, prescription eyeglasses, polarized sunglasses, and eye care accessories."
      />
      <div className="search-page">
        <div className="top-section">
          <div className="grid-section">
            <h1 className="gradient-title">Search</h1>

            <input className="search-input" type="text" placeholder="Write to Search" value={searchText} onChange={(e) => setSearchText(e.target.value.toLowerCase()) }/>
            <button className={"search-btn "  + (isMobile ? 'mobile' : 'desktop')}>Search</button>
          {searchText === "" ? (
              <div className="trending-tags">
                <p className="trending">Trending:</p>
                <div className="trending-tags-wrapper">
                  <div className="tags-column-wrapper">
                    <a onClick={(e) => setSearchText(e.target.text.toLowerCase())} href="#">Glasses</a>
                    <a onClick={(e) => setSearchText(e.target.text.toLowerCase())} href="#">Sunglasses</a>
                    <a onClick={(e) => setSearchText(e.target.text.toLowerCase())} href="#">Contacts</a>
                  </div>
                </div>
              </div>
            ):(
              <div className="trending-tags">
                <p className="result-count">{filteredFrames.length} Results for {searchText}</p>
              </div>
            )}
          </div>

        </div>

        <div className="products">
          <div className="grid-section">
            {searchText === "" ? (
              <h2>You might be interested in:</h2>
            ):(
              <h2>Products</h2>
            )}

            {searchText === "" ? (
              <div className="product-rectangles">
                {baseFrames?.slice(0, 4).map((product, index) => (
                  <ProductItem product={product} type={"glasses"} color_scheme="blue" key={index} position={index+1} />
                    ))}
              </div>
            ) : (
              <div className="product-rectangles" data-type={searchType}>
                  {searchType === "accessory" &&
                    filteredFrames?.map((product, index) => (
                      <AccessoryItem product={product} type={searchType} key={index} position={index+1} />
                    ))
                  }
                  {searchType === "contacts" &&
                    filteredFrames?.map((product, index) => (
                      <PcpItem {...product} type={product.type || searchType} key={index} position={index+1} />
                    ))
                  }
                  {(searchType === "sunglasses" || searchType === "glasses") &&
                    filteredFrames?.map((product, index) => (
                      <ProductItem product={product} type={searchType} color_scheme="blue" key={index} position={index+1} />
                    ))
                  }
                  {searchType === undefined &&
                    filteredFrames.accessories.map((product, index) => (
                      <AccessoryItem product={product} type={categories.accessory} key={index} position={index+1} />
                    )).concat(
                    filteredFrames.contacts.map((product, index) => (
                      <PcpItem {...product} type={product?.type || categories.contacts} key={filteredFrames.accessories.length + index} position={filteredFrames.accessories.length + index+1} />
                    )).concat(
                    filteredFrames.glasses.map((product, index) => (
                      <ProductItem product={product} type={categories.glasses} color_scheme="blue" key={filteredFrames.accessories.length + filteredFrames.contacts + index} position={filteredFrames.accessories.length + filteredFrames.contacts + index+1} />
                    ))
                    ))
                  }
              </div>
            )}

          </div>
        </div>
        {searchText !== "" &&
          <div className="other-results">
            <div className="grid-section">
              {blogs.length > 0 && <h2>Other Results</h2>}
              <div className="articles-list">
                {blogs.map(({ mainImage, tags, title, url }, index) => (
                  <ArticleItem
                    image={mainImage.gatsbyImageData}
                    tags={tags}
                    title={title}
                    url={`/blog/${url}`}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
        }
    </div>

    </Layout>
  )
}

const ArticleItem = ({ image, tags, title, url }) => {
  return (
    <Link to={`${url}`} className="article-item">
        <div className="image-container">
          <GatsbyImage image={image}/>
        </div>
      <div className="tags-container">
        {tags?.map((tag, index) => (
          <span
            className="tagname text h8 me-3"
            data-color={colorTags[tag]}
            key={index}
          >
            {tag}
          </span>
        ))}
      </div>
      <h6 className="title text h6">{title}</h6>
      <div class="to-article">
        <span className="text bulky">Read Article</span>
        <WebpImage fileName="ArrowRight.svg" alt="Arrow right" />
      </div>
    </Link>
  )
}


export default IndexPage

export const query = graphql`


query {
  allContentfulBlog {
    edges {
      node {
        title
        excerpt
        tags
        createdAt
        seoTitle
        seoDescription
        url
        mainImage {
          gatsbyImageData(placeholder: BLURRED, quality: 80)
        }
      }
    }
  }

  hubbleapi{
    hubbleAccessoryProducts{
      title
      handle
      description
      shopifyProductId
      variantId
      price
      productImage1
      productImage2
      id
    },
    hubbleFrameProducts {
      title
      handle
      shopifyProductId
      description
      collectionString
      glassesBasePrice
      sunglassesBasePrice
      sunglassesAvailable
      sunglassesMaleHeaderImage1
      sunglassesFemaleHeaderImage1
      productGender
      maleHeaderImage1
      maleHeaderImage2
      size
      shape
      femaleHeaderImage1
      femaleHeaderImage2
      productImage
      material
      materialFilter
      productClothImage
      sortOrder
      relatedFrames
      {
        edges {
          node {
            title
            productImage
            hubbleFrameVariants{
              title
              color
              colorFilter
              heroColor
              productImage1
              productImage2
              sunglassesWideImage1
              sunglassesWideImage2
              sunglassesWideImage3
              sunglassesProductImage1
              sunglassesProductImage2
              sunglassesProductImage3
              sunglassesProductImage4
            }
          }
        }
      }
      hubbleFrameVariants{
        title
        shopifyVariantId
        color
        colorFilter
        sku
        heroColor
        availableForSale
        productImage1
        productImage2
        productImage3
        sunglassesWideImage1
        sunglassesWideImage2
        sunglassesWideImage3
        sunglassesProductImage1
        sunglassesProductImage2
        sunglassesProductImage3
        sunglassesProductImage4
      }

    }
  }
}
`
